import { Stack, StaticAlert, IconCheckbox, Text } from '@guider-global/ui';
import People from '@mui/icons-material/People';

export interface ParticipationViewProps {
  title: string | undefined;
  alertLabel?: string;
  guideText?: { title?: string; description?: string };
  traineeText?: { title?: string; description?: string };
  checkedMentor: boolean;
  checkedMentee: boolean;
  setCheckedMentor: (checkedMentor: boolean) => void;
  setCheckedMentee: (checkedMentee: boolean) => void;
}

export function ParticipationView({
  title,
  alertLabel,
  guideText,
  traineeText,
  checkedMentor,
  checkedMentee,
  setCheckedMentor,
  setCheckedMentee,
}: ParticipationViewProps) {
  return (
    <Stack
      direction={'column'}
      justifyContent={'flex-start'}
      width={{ xs: '100%', md: '480px' }}
    >
      <Text text={title} variant="h2" lineHeight={'115%'} />
      {alertLabel && (
        <StaticAlert
          message={alertLabel}
          fullWidth
          sx={{ backgroundColor: 'warning.transparent', color: 'warning.main' }}
        />
      )}
      <IconCheckbox
        icon={<People />}
        isChecked={checkedMentor}
        onChange={() => setCheckedMentor(!checkedMentor)}
        heading={guideText?.title}
        subtitle={guideText?.description}
        data-cy="register-participation-guide-checkbox"
      />
      <IconCheckbox
        icon={<People />}
        isChecked={checkedMentee}
        onChange={() => setCheckedMentee(!checkedMentee)}
        heading={traineeText?.title}
        subtitle={traineeText?.description}
        data-cy="register-participation-trainee-checkbox"
      />
    </Stack>
  );
}
